var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('ul',_vm._l((_vm.steps),function(step,index){return _c('li',{key:step.id,class:{
                'is-active': step.id == _vm.currentStep,
                'is-inactive': step.id != _vm.currentStep,
                'last-step': step.id == _vm.steps.length,
                'first-step': step.id == 1,
                behind: step.id > _vm.currentStep,
            }},[_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"step-id",class:{ last: index == _vm.steps.length - 1 }},[_c('div',[_vm._v(_vm._s(step.id)+".")])]),_c('div',{staticClass:"step-label"},[_c('div',[_vm._v(_vm._s(step.label))])])]),(step.id != _vm.currentStep)?_c('div',{staticClass:"step-background"}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }